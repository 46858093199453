/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Grid, GridSize, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { SelectOption } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import FormInput from '../../../modules/shared/form/FormInput';
import { TreatmentProductsAutoTreatContext } from '../contexts/TreatmentProductsAutoTreatContext';
import PaginationBanner from '../../../modules/shared/machineManagement/PaginationBanner';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface TreatmentProductsAutoTreatFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
  tableFilters?: any;
  setTableFilters?: any;
}

const TreatmentProductsAutoTreatFilterBar: React.FC<TreatmentProductsAutoTreatFilterBarProps> = (props) => {
  const {
    filters = [],
    filterValues,
    filterChoices,
    machineChoices,
    machineChoicesLoading,
    tableFilters,
    setTableFilters,
  } = props;

  const {
    fetchCachedTreatmentProductsOptionsAutoTreat,
    fetchTreatmentProductsOptionsAutoTreat,
    treatmentProducts,
    fetchTreatmentProductsAutoTreat,
    fetchCachedTreatmentProductsAutoTreat,
    treatmentProductsLoading,
    treatmentProductsRefreshedAt,
    activeMachine,
    setActiveMachine,
    handleGenericFilterChange,
    genericFilter,
    handleStatusFilterChange,
    statusFilter,
    handleApiLinkedFilterChange,
    apiLinkedFilter,
    options,
  } = React.useContext(TreatmentProductsAutoTreatContext);

  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);
  const [onPremPage, setOnPremPage] = React.useState<number>(0);

  React.useEffect(() => {
    if (localStorage.getItem('treatmentProductsAutoTreatMachine') && treatmentProducts.length === 0) {
      fetchCachedTreatmentProductsAutoTreat(localStorage.getItem('treatmentProductsAutoTreatMachine'));
      fetchCachedTreatmentProductsOptionsAutoTreat(localStorage.getItem('treatmentProductsAutoTreatMachine'));
      fetchTreatmentProductsAutoTreat(
        localStorage.getItem('treatmentProductsAutoTreatMachine'),
        genericFilter,
        statusFilter,
        apiLinkedFilter,
      );
      fetchTreatmentProductsOptionsAutoTreat(localStorage.getItem('treatmentProductsAutoTreatMachine'));
    }
  }, []);

  React.useEffect(() => {
    console.log('useEffect running');
    if (localStorage.getItem('customerAutoTreatMachine')) {
      setActiveMachine(
        machineChoices.filter((choice) => choice?.value === localStorage.getItem('customerAutoTreatMachine'))?.[0],
      );
    }
  }, [machineChoices]);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('treatmentProductsAutoTreatMachine', value);
    fetchCachedTreatmentProductsAutoTreat(value);
    fetchCachedTreatmentProductsOptionsAutoTreat(value);
    fetchTreatmentProductsAutoTreat(value, genericFilter, statusFilter, apiLinkedFilter);
    fetchTreatmentProductsOptionsAutoTreat(value);

    setActiveMachine(machineChoices.filter((choice) => choice?.value === value)?.[0]);
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={activeMachine?.value}
              groupedChoices={{
                'Online Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'online',
                ),
                'Offline Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'offline',
                ),
                'Expired Machines': machineChoices.filter((machine) => machine.subscription_active === false),
              }}
              defaultChoice={defaultMachineChoice()}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={genericFilter}
              onChange={(value) => handleGenericFilterChange(value)}
              label="Generic Filter"
            />
          </Grid>
          <Grid item xs={1}>
            <SelectInput
              value={statusFilter}
              choices={[
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' },
                { label: 'All', value: 'All' },
              ]}
              onChange={(value) => handleStatusFilterChange(value)}
              label="Status"
            />
          </Grid>
          <Grid item xs={1}>
            <SelectInput
              value={apiLinkedFilter}
              choices={[
                { label: 'All', value: 'All' },
                { label: 'Yes', value: 'True' },
                { label: 'No', value: 'False' },
              ]}
              onChange={(value) => handleApiLinkedFilterChange(value)}
              label="API Linked"
            />
          </Grid>
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={treatmentProductsLoading || activeMachine?.value === 'Select A Machine'}
              onClick={() => {
                fetchTreatmentProductsAutoTreat(activeMachine?.value, genericFilter, statusFilter, apiLinkedFilter);
                fetchTreatmentProductsOptionsAutoTreat(activeMachine?.value);
              }}
            >
              {treatmentProductsRefreshedAt ? 'Load' : 'Load'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!treatmentProductsLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {treatmentProductsLoading ? (
              <span style={{ marginLeft: 20, position: 'relative', top: 19 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 10 }}>
                Last Loaded: {convertToLocalTime(treatmentProductsRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {treatmentProducts?.length === 500 && (
            <PaginationBanner
              totalRecords={treatmentProducts?.length}
              currentPage={onPremPage}
              onPageChange={(newPage) => setOnPremPage(newPage)}
            />
          )}
        </Grid>
      </Grid>

      <br />
      <Typography variant="h2" component="h2" style={{ marginTop: 10, marginBottom: -18, marginLeft: 5 }}>
        Live Filters
      </Typography>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.liquid_name}
              onChange={(value) => setTableFilters({ ...tableFilters, liquid_name: value })}
              label="Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.liquid_name_desc}
              onChange={(value) => setTableFilters({ ...tableFilters, liquid_name_desc: value })}
              label="Description"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() => {
                setTableFilters({});
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TreatmentProductsAutoTreatFilterBar;
